import * as React from "react"
import FullApp from "../FullApp";
import Layout from "../templates/layout";
import Regulations from "../components/pages/regulations";

export default (props) => (
  <FullApp>
    <Layout {...props}  router={props} isDark={true}>
      <Regulations />
    </Layout>
  </FullApp>
);