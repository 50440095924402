import * as React from "react";
import {
  Container, Grid, Header, Segment
} from "semantic-ui-react";
import '../../css/regulations.css';
import MainTopBg from "../backgrounds/others/MainTopBg";
import SEO from "../seo";


export default class Regulations extends React.Component {
  render() {
    return (
      <MainTopBg style={{backgroundSize: 'contain', backgroundPosition: 'top'}}>
        <Segment basic id='regulations-container'>
          <SEO title="Regulamin"/>
          <Segment basic>
            <Container>
              <Grid>
                <Grid.Column>
                  <p>
                    <Header as='h1' textAlign="center">Regulamin Sklepu Internetowego Pure4Me</Header>
                    <ol>
                      <li className='main-list-item'>POSTANOWIENIA WSTĘPNE</li>
                      <p>
                        <ol>
                          <li>
                            Niniejszy Regulamin określa zasady i warunki korzystania ze Sklepu Internetowego dostępnego pod adresem www.pure4me.pl , prowadzonego przez spółkę Natur Produkt Zdrovit Sp. z o. o. z siedzibą w Warszawie adres
                            siedziby ul. Nocznickiego 31, 01 -198 Warszawa, wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy , pod numerem
                            KRS 80429, nr NIP: 118-00-09-700, o kapitale zakładowym 201 500 zł. (dalej zwaną jako „Usługodawca”), a w szczególności określa zasady składania Zamówień i zawierania umów sprzedaży przy wykorzystaniu środków
                            porozumiewania się na odległość, a także korzystania przez Klientów z usług Sklepu Internetowego.
                          </li>
                          <li>
                            Usługi świadczone w ramach Sklepu Internetowego kierowane są do konsumentów oraz do przedsiębiorców w rozumieniu art. 43 (1) Kodeksu Cywilnego, korzystających ze Sklepu Internetowego.
                          </li>
                          <li>
                            Regulamin Sklepu Internetowego dostępny jest na stronie internetowej Sklepu w sposób umożliwiający pozyskanie, odtwarzanie i utrwalanie jego treści poprzez wydrukowanie lub zapisanie na nośniku w każdej chwili oraz
                            jest udostępniany Klientowi po zawarciu umowy w sposób umożliwiający dostęp do niego w przyszłości.
                          </li>
                        </ol>
                      </p>
                      <li className='main-list-item'>DEFINICJE</li>

                      <ol>
                        <li>
                          <b>Ankieta</b> – wypełniony przez Klienta formularz online pozwalający na uzyskanie informacji o Kliencie niezbędnych do skomponowania zindywidualizowanego Produktu;
                        </li>
                        <li>
                          <b>Dzień roboczy</b> – każdy dzień roboczy od poniedziałku do piątku z wyłączeniem dni ustawowo wolnych od pracy;
                        </li>
                        <li>
                          <b>Dostawca</b> – podmiot z którym współpracuje Usługodawca i ma podpisaną umowę w zakresie dokonywania dostaw Produktów takie jak firmy kurierskie, Poczta Polska SA z siedzibą w Warszawie;
                        </li>
                        <li><b>Formularz zamówienia</b> – udostępniony w Sklepie Internetowym interaktywny formularz umożliwiający Klientowi złożenie Zamówienia poprzez kliknięcie opcji „Zamawiam i płacę”;</li>
                        <li><b>Klient</b> – osoba fizyczna, która posiada pełną zdolność do czynności prawnych, osoba fizyczna prowadząca działalność gospodarczą, osoba prawna lub jednostka organizacyjna nie będąca osobą prawną, której
                          przepisy
                          szczególne przyznają zdolność prawną, zawierająca umowę sprzedaży z Usługodawcą;
                        </li>
                        <li><b>Kodeks cywilny</b> – ustawa z dnia 23 kwietnia 1964 r. (Dz. U.2019.1145 t.j. z dnia 2019.06.19);</li>
                        <li><b>Konsument</b> – Klient dokonujący z Usługodawcą czynności prawnej niezwiązanej bezpośrednio z jego działalnością gospodarczą lub zawodową;</li>
                        <li><b>Konto Klienta</b> – podstrona Sklepu Internetowego oznaczona indywidualną nazwą w postaci adresu e-mail Klienta, chroniona indywidualnym hasłem wskazanym przez Klienta, na której gromadzone są informacje o
                          Kliencie w
                          tym informacje o złożonych Zamówieniach. W ramach Konta, Klient ma możliwość min. zarządzania swoimi danymi osobowymi, a także może składać Zamówienia po zalogowaniu bez konieczności podawania swoich danych
                          osobowych;
                        </li>
                        <li><b>Newsletter</b> – usługa polegająca na przesyłaniu informacji marketingowych, reklamowych, handlowych i promocyjnych, w szczególności dotyczącej oferty Produktów i świadczonych usług w formie listu
                          elektronicznego za
                          pośrednictwem poczty elektronicznej na podany przez Użytkownika adres poczty elektronicznej;
                        </li>
                        <li><b>Produkt</b> – suplement diety w rozumieniu ustawy z dnia 25 sierpnia 2006 r. o bezpieczeństwie żywności i żywienia wytworzony na indywidualne Zamówienie Klienta, którego skład został indywidualnie skomponowany
                          dla
                          Klienta na podstawie danych zawartych w Ankiecie;
                        </li>
                        <li><b>Regulamin</b> – niniejszy dokument określający zasady korzystania ze Sklepu Internetowego oraz świadczonych za jego pośrednictwem usług drogą elektroniczną związanych z dokonywaniem Zamówień na Produkty, będący
                          regulaminem o którym mowa w art. 8 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną;
                        </li>
                        <li><b>Rejestracja</b> – jednorazowa, bezpłatna czynność polegająca na utworzeniu przez Klienta , Konta Klienta dokonana z wykorzystaniem formularza rejestracyjnego udostępnionego przez Usługodawcę na stronie
                          internetowej
                          Sklepu Internetowego;
                        </li>
                        <li><b>RODO</b> – Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
                          takich
                          danych oraz uchylenia dyrektywy 95/46/WE;
                        </li>
                        <li><b>Sklep Internetowy</b> - serwis internetowy dostępny pod adresem WWW.pure4me.pl za pośrednictwem którego Klient może składać Zamówienia a także korzystać z pozostałych usług Sklepu;</li>
                        <li><b>Umowa sprzedaży</b> – zawarta w wyniku Zamówienia między Klientem a Usługodawcą, umowa sprzedaży w rozumieniu Kodeksu Cywilnego;</li>
                        <li><b>Usługi elektroniczne</b> – usługi świadczone przez Sprzedawcę drogą elektroniczną w rozumieniu przepisów ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą elektroniczną (Dz.U. nr 144, poz. 1204 ze zm.);
                        </li>
                        <li><b>Ustawa o prawach konsumenta</b> – ustawa z dnia 30 maja 2014 r. o prawach konsumenta (Dz. U. 2014, Nr 827);</li>
                        <li><b>Ustawa o świadczeniu usług drogą elektroniczną</b> – ustawa z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. Nr 144, poz. 1204 ze zm.);</li>
                        <li><b>Usługodawca</b> - Natur Produkt Zdrovit Sp. z o. o. z siedzibą w Warszawie adres siedziby ul. Nocznickiego 31, 01 -198 Warszawa, wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez
                          Sąd
                          Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy , pod numerem KRS 80429, nr NIP: 118-00-09-700, o kapitale zakładowym 201 500 zł.;
                        </li>
                        <li><b>Użytkownik</b> – każdy pojedynczy użytkownik sieci Internet, korzystający z Usług elektronicznych świadczonych w Sklepie Internetowym;</li>
                        <li><b>Zamówienie</b> – oświadczenie woli Klienta stanowiące ofertę zawarcia Umowy sprzedaży na odległość za pośrednictwem Sklepu Internetowego określająca rodzaj i liczbę Produktów oraz inne wymagane prawem treści
                          będące
                          przedmiotem Umowy sprzedaży;
                        </li>
                      </ol>
                      <br/>

                      <li className='main-list-item'>ZASADY KORZYSTANIA ZE SKLEPU INTERNETOWEGO</li>
                      <ol>
                        <li>W celu korzystania ze Sklepu Internetowego konieczne jest spełnienie przez system teleinformatyczny Klienta następujących wymagań:</li>
                        <ul>
                          <li> dostęp do sieci Internet;</li>
                          <li> przeglądarka internetowa Firefox for Desktop w wersji 51 lub nowszej, Firefox for Android w wersji 51 lub nowszej, Firefox for IOS w wersji 5 lub nowszej, Microsoft EDGE w wersji 14 lub nowszej, Chrome w wersji
                            56 lub nowszej, Opera w wersji 43 lub nowszej, Safari w wersji 10 lub nowszej, IOS Safari w wersji 10;
                          </li>
                          <li> włączenie w przeglądarce internetowej Cookies oraz Javascript;</li>
                          <li> program do odczytu i zapisywania plików formatu PDF;</li>
                        </ul>
                        <li>Korzystanie z Usług elektronicznych, może wiązać się z zagrożeniem po stronie każdego użytkownika sieci Internet, polegającym na możliwości wprowadzenia do systemu teleinformatycznego Klienta szkodliwego
                          oprogramowania oraz pozyskania i modyfikacji jego danych przez osoby nieuprawnione. Zaleca się Klientowi stosowanie właściwych środków technicznych, które zminimalizują ich wystąpienie.
                        </li>
                        <li>W celu zapewnienia bezpieczeństwa świadczonych usług oraz przesyłania danych osobowych, Usługodawca podejmuje środki adekwatne do zagrożenia.</li>
                        <li>Usługodawca informuje, że w Sklepie Internetowym wykorzystywane są mechanizmy plików cookies. Zasady ich wykorzystania reguluje Polityka cookies dostępna na stronach internetowych Sklepu.</li>
                        <li>Wszystkie prawa do Sklepu Internetowego, w tym majątkowe prawa autorskie, prawa własności intelektualnej do jego nazwy, domeny internetowej, strony internetowej Sklepu Internetowego lub prawa do korzystania z nich,
                          a także prawa do formularzy, logotypów należą do Usługodawcy, a korzystanie z nich może następować wyłącznie w sposób określony i zgodny z Regulaminem, a w innych przypadkach za zgodą Usługodawcy.
                        </li>
                        <li>W celu korzystania z niektórych Usług elektronicznych lub złożenia Zamówienia jest wymagane posiadanie: aktywnego Konta lub aktywnego konta poczty elektronicznej.</li>
                        <li>Wszystkie Produkty oferowane przez Usługodawcę są fabrycznie nowe, wolne od wad fizycznych i prawnych oraz znajdują się w legalnym obrocie na terenie Rzeczpospolitej Polskiej na podstawie stosownych przepisów prawa
                          lub decyzji właściwych władz administracyjnych.
                        </li>
                        <li>Ceny Produktów oferowanych w Sklepie Internetowym podane są w PLN i zawierają podatek VAT i nie zawierają kosztów dostawy</li>
                        <li>Klient zobowiązany jest korzystać ze Sklepu Internetowego w sposób zgodny z przepisami prawa, postanowieniami Regulaminu oraz ogólnie przyjętymi w sieci Internet zasadami.</li>
                        <li>Zakazane jest dostarczanie i przekazywanie treści zabronionych przez przepisy prawa, w tym treści propagujących przemoc, zniesławiających lub naruszających dobra osobiste i inne prawa osób trzecich.</li>
                      </ol>
                      <br/>

                      <li className='main-list-item'>RODZAJ I ZAKRES ŚWIADCZONYCH USŁUG</li>
                      <ol>
                        <li>Usługodawca umożliwia Klientom korzystanie z następujących bezpłatnych Usług elektronicznych:
                          <ul>
                            <li> prowadzenia Konta Klienta:</li>
                            <li> udostępnienie Ankiety w celu jej wypełnienia;</li>
                            <li> udostępnienie Formularza Zamówienia w celu wypełnienia i złożenia Zamówienia;</li>
                            <li> udostępnienie Regulaminu oraz oferty Sklepu Internetowego;</li>
                            <li> świadczenie usługi Newsletter;</li>
                          </ul>
                        </li>
                        <li>W celu rozpoczęcia korzystania z usługi prowadzenia Konta Klienta konieczna jest uprzednia Rejestracja w Sklepie Internetowym, która następuje poprzez wypełnienie i przesłanie Usługodawcy formularza
                          rejestracyjnego dostępnego na stronach internetowych Sklepu.
                        </li>
                        <li>Umowa o prowadzenie Konta Klienta zawarta jest z chwilą otrzymania przez Klienta potwierdzenia Rejestracji na czas nieoznaczony.</li>
                        <li>Usługodawca udostępnia Ankietę w celu jej wypełnienia celem skomponowania zindywidualizowanego Produktu oraz Formularz zamówienia umożliwiający dokonanie zakupu zindywidualizowanego Produktów. Usługa
                          udostępnienia Ankiety i Formularza zamówienia świadczona jest przez czas oznaczony i ulega rozwiązaniu z chwilą złożenia Zamówienia tj. z chwilą wysłania Formularza zamówienia do Usługodawcy.
                        </li>
                      </ol>
                      <br/>

                      <li className='main-list-item'>NEWSLETTER</li>
                      <ol>
                        <li>Użytkownik może wyrazić dobrowolną zgodę na otrzymywanie Newslettera – usługi udostępnianej bezpłatnie przez Usługodawcę.</li>
                        <li>Newsletter wysyłany jest wyłącznie do Użytkowników, którzy zamówili Newsletter lub wyrazili zgodę na kierowanie informacji marketingowych, promocyjnych i reklamowych na podane dane kontaktowe (adres e-mail lub
                          numer telefonu) podczas składania Zamówienia.
                        </li>
                        <li>W celu aktywacji usługi Newsletter należy w formularzu elektronicznym dostępnym na stronie Sklepu Internetowego wpisać swój adres poczty elektronicznej, zaakceptować Regulamin, i wcisnąć przycisk „Zapisz się”, a
                          następnie aktywować link zawarty w wiadomości wysłanej automatycznie od Usługodawcy, na podany adres poczty elektronicznej.
                        </li>
                        <li>W ramach usługi Newsletter, za pośrednictwem poczty elektronicznej, na podany przez Użytkownika adres e-mail, wysyłana jest informacja w formie listu elektronicznego (e-mail) zawierająca biuletyn informacyjny
                          (Newsletter) Sklepu Internetowego, a także inne informacje handlowe wysyłane przez Usługodawcę w swoim imieniu i na zlecenie osób trzecich.
                        </li>
                        <li>Klient może w każdej chwili zrezygnować z otrzymywania informacji Newslettera. W tym celu może skorzystać z opcji wypisania się z Newslettera, dostępnej w stopce każdej wiadomości lub skontaktować się Usługodawcą i
                          przesłać rezygnację na adres sklep@pure4me.pl , a następnie aktywować link zawarty w wiadomości wysłanej automatycznie na podany przez Użytkownika adres mailowy. Usługa dezaktywacji zostanie potwierdzona przez
                          Usługodawcę poprzez wysłanie wiadomości na adres poczty elektronicznej Użytkownika.
                        </li>
                        <li>Użytkownik może w każdej chwili dokonać ponownego zamówienia usługi Newsletter.</li>
                        <li>W chwili wycofania zgody na Newsletter, Usługodawca usuwa dane osoby (adres e-mail) z Newslettera i zaprzestaje dalszego przesyłania Newslettera.</li>

                      </ol>
                      <br/>

                      <li className='main-list-item'>SKŁADANIE ZAMÓWIEŃ</li>
                      <ol>
                        <li>Warunkiem złożenia zamówienia za pośrednictwem Sklepu Internetowego jest zapoznanie się z Regulaminem. Złożenie Zamówienia za pośrednictwem Sklepu Internetowego oznacza, że Klient zapoznał się z treścią Regulaminu
                          oraz zobowiązuje się do jego przestrzegania.
                        </li>
                        <li>Klient może składać Zamówienia za pośrednictwem Sklepu Internetowego przez 24 (dwadzieścia cztery) godziny na dobę, 7 (siedem) dni w tygodniu.</li>
                        <li>W celu złożenia Zamówienia należy :

                          <ul>
                            <li> dokonać Rejestracji Klienta poprzez wypełnienie formularza rejestracyjnego zamieszczonego na stronie internetowej Sklepu i utworzyć Konto Klienta;</li>
                            <li> wypełnić Ankietę, która znajduje się w podstronie Konta Klienta;</li>
                            <li> po utworzeniu Konta Klienta, na podstawie wypełnionej przez Klienta Ankiety, zostanie przygotowane dla Klienta oferta na Produkt wraz z cenami, Klient może modyfikować skład Produktu, cena Produktu jest
                              zależna od składników jakie zamawia Klient;
                            </li>
                            <li> po dokonaniu wyboru Produktu oraz po wypełnieniu wszystkich istotnych elementów Zamówienia, w celu dokonania Zamówienia Klient zobowiązany jest wysłać drogą elektroniczną Formularz zamówienia ;</li>
                          </ul>

                        </li>
                        <li>Wybór opcji „Zamawiam i płacę” jest jednoznaczny z zamknięciem Zamówienia i wiąże się z obowiązkiem zapłaty za wybrany Produkt oraz za dostawę zgodnie z wygenerowanym podsumowaniem Zamówienia. Po wybraniu tej opcji
                          Zamówienie nie może zmienić zawartości.
                        </li>
                        <li>W momencie wyboru opcji „Zamawiam i płacę”, zostaje zawarta między Usługodawca a Klientem Umowa sprzedaży przez Usługodawcę Produktu określonego w potwierdzeniu złożenia zamówienia, przesłanym przez Usługodawcę
                          Klientowi drogą elektroniczną w formie wiadomości e-mail, po przyjęciu przez Usługodawcę Zamówienia do realizacji.
                        </li>
                        <li>Dla danego Zamówienia wiążąca jest cena obowiązująca w momencie jego złożenia.</li>
                        <li>O terminie realizacji Zamówienia Usługodawca informuje podczas składania Zamówienia, w momencie wybierania przez Klienta opcji dostawy oraz drogą elektroniczną w momencie potwierdzenia złożenia Zamówienia przez
                          Klienta.
                        </li>
                        <li>Przygotowanie Zamówienia i wysyłka Produktu następuje nie później niż w ciągu 14 (czternastu) dni roboczych licząc od zaksięgowania wpłaty za Zamówienie dokonanej za pośrednictwem szybkiego przelewu internetowego
                          PayU, przelewu tradycyjnego lub płatności kartą kredytową;
                        </li>
                        <li>Jeżeli Klient wybrał opcję zapłaty przelewem bankowym, Klient otrzymuje informację że Usługodawca otrzymał Zamówienie i czeka na wpłatę za Zamówienie. Po zaksięgowaniu wpłaty Klient zostanie powiadomiony drogą
                          elektroniczną w formie wiadomości e-mail, że Zamówienie zmieniło status na „Zamówienie w trakcie realizacji”. W przypadku braku wpłaty w ciągu 3 dni od dnia złożenia Zamówienia, Zamówienie zostanie anulowane.
                        </li>
                        <li> Zawarcie umowy sprzedaży z Usługodawcą i akceptacja postanowień Regulaminu oznacza wyrażenie zgody na wystawienie i udostępnienie Klientowi faktur oraz faktur korygujących w formie elektronicznej, zgodnie z art.
                          106n ust. 1 ustawy z dnia 11 marca 2004 r. o podatku od towarów i usług (Dz. U. z 2004 r. nr 54 poz. 535 ze zm.). Wyrażenie zgody na udostępnianie faktur w formie elektronicznej jest równoznaczne z rezygnacją z
                          otrzymywania faktur w wersji papierowej.
                        </li>
                        <li> Usługodawca wystawia paragon lub fakturę elektroniczną w momencie wysyłki zamówienia z magazynu. Paragon zostanie umieszczony w przesyłce wraz z Produktem. Link do pobrania faktury w formie PDF zostanie wysłany
                          Klientowi za pośrednictwem poczty elektronicznej na adres email wskazany przez Klienta przy składaniu Zamówienia lub przy zakładaniu Konta Klienta. Dodatkowo Klient będzie mógł pobrać fakturę po zalogowaniu do Konta
                          Klienta. Wysłanie przez Usługodawcę linku do pobrania faktury, korekty faktury w formie PDF jest jednoznaczne z odbiorem tych dokumentów przez Klienta.
                        </li>
                        <li> Usługodawca zastrzega sobie prawo odmowy realizacji części albo całości zamówienia w przypadkach, gdy:
                          <ul>
                            <li> elektroniczny formularz zamówienia nie został wypełniony poprawnie, a Usługodawca nie może skontaktować się z Klientem w celu weryfikacji jego danych teleadresowych;</li>
                            <li> Produkt został wycofany z obrotu lub wstrzymany w obrocie na terenie Rzeczpospolitej Polskiej po złożeniu Zamówienia;</li>
                            <li> takie uprawnienie lub obowiązek wynika z przepisów prawa powszechnie obowiązującego.</li>
                          </ul>
                        </li>
                        <li>Jeżeli Usługodawca odmawia realizacji zamówienia zgodnie z ust. 12 powyżej lub nie jest w stanie zrealizować zamówienia Klienta (incydentalna niezgodność stanów magazynowych bądź incydentalne uszkodzenie towaru),
                          niezwłocznie, najpóźniej w terminie 5 (pięciu) dni roboczych licząc od daty złożenia Zamówienia, zawiadomi o tym Klienta drogą elektroniczną i zwróci Klientowi uiszczoną przez niego cenę za Produkt wraz poniesionymi
                          przez Klienta kosztami dostawy;
                        </li>
                      </ol>
                      <br/>

                      <li className='main-list-item'>PŁATNOŚCI</li>
                      <ol>
                        <li>Usługodawca stosuje wyłącznie system płatności bezgotówkowych.</li>
                        <li>Cena Produktu podana jest w polskich złotych (PLN) i jest ceną brutto.</li>
                        <li>Informacja o całkowitej wartości zamówienia przedstawiana jest Klientowi przed podjęciem przez niego decyzji co do złożenia Zamówienia.</li>
                        <li>Klient po kliknięciu w ikonkę „Zamawiam i płacę”, dokonuje akceptacji kwoty zamówienia, a następnie zostanie przekierowany do formularza zapłaty dostępnego na stronie internetowej podmiotu realizującego płatność.
                        </li>
                        <li>Klient może dokonać płatności wybierając jedną z poniższych metod:
                          <ul>
                            <li> za pośrednictwem podmiotów realizujących płatność, zgodnie z regulaminem obowiązującym u danego podmiotu;</li>
                            <li> przelewem bankowym na konto bankowe. Dane do przelewu zostaną wyświetlone oraz wysłane drogą elektroniczną na podany przez Klienta adres e-mail, jeżeli wybrana została płatność przelewem.</li>
                            <li> w ramach PayU (natychmiastowe przelewy bankowe, karty): Visa, Visa Electron, MasterCard, MasterCard Electronic, Maestro), InPay (bitcoin) oraz PayPal. Podmiotem świadczącym obsługę płatności online jest PayU
                              S.A. Rozliczenia transakcji kartą płatniczą i e-przelewem przeprowadzane są za pośrednictwem PayU.pl.
                            </li>
                          </ul>
                        </li>
                        <li>Klient, który składa Zamówienie i wybiera opcję zapłaty przelewem na konto bankowe, powinien dokonać zapłaty za zamówiony Produkt oraz dostawę w terminie 3 (trzech) dni od dnia otrzymania potwierdzenia złożenia
                          Zamówienia. W przypadku braku zaksięgowania płatności we wskazanym terminie, Zamówienie zostanie anulowane, o czym Klient zostanie poinformowany drogą elektroniczną.
                        </li>

                      </ol>
                      <br/>

                      <li className='main-list-item'>DOSTAWA</li>
                      <ol>
                        <li>Dostawa Produktów jest ograniczona do terytorium Rzeczpospolitej Polskiej;</li>
                        <li>Usługodawca dokonuje dostawy Produktu przez kuriera.</li>
                        <li>Koszty dostawy są wskazane w trakcie składania Zamówienia i są dodawane do wartości Zamówienia;</li>
                        <li>Klient zostanie powiadomiony drogą elektroniczną na podany przez Klienta adres e-mail, że Zamówienie zostało zrealizowane i przekazane do wysyłki.</li>
                        <li>Zamówione przez Klienta Produkty są przesyłane do Klienta firmą kurierską działającą na zlecenie Klienta.</li>
                        <li>W przypadku, gdy Zamówienie nie może zostać doręczone z winy Klienta (błędny adres, nieuzasadniona odmowa przyjęcia), Usługodawca zastrzega sobie prawo obciążenia Klienta kosztami ponownego transportu zgodnie z
                          cennikiem dostaw firmy kurierskiej.
                        </li>
                        <li>7. Przed odbiorem przesyłki od firmy kurierskiej Klient zobowiązany jest sprawdzić,
                          czy opakowanie nie uległo uszkodzeniu podczas transportu. W przypadku, gdy przesyłka nosi znamiona uszkodzenia należy odmówić przyjęcia przesyłki, sporządzić w obecności przewoźnika protokół zastrzeżeń, a następnie
                          skontaktować się z Usługodawcą za pośrednictwem poczty elektronicznej bądź telefonicznie w celu poinformowania o uszkodzonej przesyłce.
                        </li>
                        <li>W przypadku nieodebrania przez Klienta przesyłki z uwagi na uszkodzenie jej opakowania, po otrzymaniu od Klienta informacji w tym zakresie Usługodawca, według wyboru Klienta, ponownie wyśle Klientowi zamówione
                          Produkty lub dokona Klientowi zwrotu wartości Zamówienia oraz kosztów dostawy.
                        </li>
                        <li>W przypadku zaginięcia przesyłki wysłanej za pośrednictwem firmy kurierskiej
                          i otrzymania od Klienta informacji o niedoręczeniu zamówionego towaru, Usługodawca zwraca Klientowi uiszczone przez niego kwoty w terminie 10 (dziesięciu) dni od dnia otrzymania informacji o niedoręczeniu Zamówienia.
                        </li>
                      </ol>
                      <br/>

                      <li className='main-list-item'>REKLAMACJE</li>
                      <ol>
                        <li>Usługodawca zobowiązany jest do sprzedaży Klientowi Produktu nie wadliwego. W razie niezgodności Produktu z umową bądź w zakresie nieprawidłowości w świadczeniu usług drogą elektroniczną Klient powinien zgłosić Usługodawcy reklamację za pośrednictwem poczty elektronicznej na adres: reklamacje@pure4me.pl lub pisemnie na adres:Natur Produkt Zdrovit sp. zo.o., ul. Nocznickiego 31; 01-918 Warszawa.</li>
                        <li>Do czasu zamknięcia postępowania reklamacyjnego należy bezwzględnie zachować wszelkie reklamowane Produkty.</li>
                        <li>Jeżeli Produkt ma wadę, Klient może złożyć oświadczenie o obniżeniu ceny albo odstąpieniu od umowy, chyba że Usługodawca niezwłocznie i bez nadmiernych niedogodności dla Klienta wymieni Produkt wadliwy na wolny od wad albo wadę usunie. Ograniczenie to nie ma zastosowania, jeżeli Produkt był już wymieniony przez Usługodawcę albo Usługodawca nie uczynił zadość obowiązkowi wymiany Produktu na wolny od wad lub usunięcia wady.</li>
                        <li>Klient będący Konsumentem może zamiast zaproponowanego przez Usługodawcę usunięcia wady żądać wymiany Produktu na wolny od wad albo zamiast wymiany Produktu żądać usunięcia wady, chyba że doprowadzenie towaru do zgodności z umową w sposób wybrany przez Klienta jest niemożliwe albo wymagałoby nadmiernych kosztów w porównaniu ze sposobem proponowanym przez Usługodawcę.</li>
                        <li>Klient nie może odstąpić od umowy w ramach procedury opisanej w niniejszym paragrafie, jeżeli wada jest nieistotna.</li>
                        <li>Jeśli Produkt sprzedany ma wadę, Klient może również żądać wymiany Produktu na wolny od wad albo usunięcia wady. Usługodawca jest wówczas obowiązany wymienić Produkt wadliwy na wolny od wad lub usunąć wadę w rozsądnym czasie bez nadmiernych niedogodności dla Klienta. Usługodawca może odmówić zadośćuczynienia żądaniu Klienta, jeżeli doprowadzenie do zgodności z umową Produktu wadliwego w sposób wybrany przez Klienta jest niemożliwe albo w porównaniu z drugim możliwym sposobem doprowadzenia do zgodności z umową wymagałoby nadmiernych kosztów.</li>
                        <li>Reklamowane Produkty należy dostarczyć do Usługodawcy wraz z reklamacją.</li>
                        <li>Usługodawca nie przyjmuje przesyłek za pobraniem.</li>
                        <li>Reklamacja powinna zawierać : imię i nazwisko Klienta, opis przyczyny reklamacji, adres korespondencyjny i adres poczty elektronicznej Klienta, numer Zamówienia , numer telefonu kontaktowego</li>
                        <li> Usługodawca ustosunkuje się do zgłoszonej przez Klienta reklamacji w ciągu 14 dni od dnia jej otrzymania. W braku ustosunkowania się przez Usługodawcę we wskazanym terminie uważa się, że żądanie uznano za uzasadnione.</li>
                        <li> O rozstrzygnięciu reklamacji Usługodawca powiadomi Klienta pisemnie na podany przez niego adres poczty elektronicznej lub na wskazany przez Klienta adres korespondencyjny przesyłką poleconą.</li>
                        <li> W przypadku odrzucenia reklamacji przez Usługodawcę,  Klient może skorzystać z pomocy miejskiego lub powiatowego rzecznika konsumentów, wojewódzkich inspektoratów Inspekcji Handlowej lub jednej z organizacji społecznych, do której zadań statutowych należy ochrona konsumentów np. Federacji Konsumentów. Szczegółowe informacje o pozasądowych sposobach rozpatrywania reklamacji i dochodzenia roszczeń dostępne są w siedzibach lub na stronach internetowych rzeczników konsumentów, wojewódzkich inspektoratów Inspekcji Handlowej, właściwych organizacji społecznych oraz pod adresami dostępnymi na stronie Urzędu Ochrony Konkurencji i Konsumentów: www.uokik.gov.pl/wazne_adresy.php.</li>
                        <li> W przypadku odrzucenia reklamacji przez Usługodawcę, Klient może skorzystać z postępowania pozasądowego w celu rozstrzygnięcia sporu. Podmiotem właściwym do prowadzenia takiego postępowania jest Wojewódzki Inspektor Inspekcji Handlowej w Warszawie, którego strona internetowa dostępna jest pod adresem: http://wiih.org.pl.</li>
                        <li> Klient może również skorzystać z unijnej platformy internetowego rozstrzygania sporów. Platforma internetowego rozstrzygania sporów pomiędzy Klientami a przedsiębiorcami na szczeblu unijnym dostępna jest pod adresem http://ec.europa.eu/consumers/odr/.</li>
                      </ol><br />

                      <li className='main-list-item'>DANE OSOBOWE</li>
                      <ol>

                        <li>Administratorem danych osobowych (w rozumieniu art. 4 pkt. 7 RODO) Klientów, korzystających z funkcjonalności Sklepu Internetowego jest Usługodawca, to jest Spółka Natur Produkt Zdrovit Sp. z o. o. ul. Nocznickiego 31, 01-918 Warszawa, z którym istnieje możliwość kontaktu poprzez adres mailowy: rodo@zdrovit.pl;</li>

                        <li>Dane osobowe Klientów, mogą być przetwarzane w następujących celach oraz na poniższych podstawach prawnych:
                          <ol type="a">
                            <li>przyjmowania zamówień oraz realizacji umowy sprzedaży (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. b RODO),</li>
                            <li>bieżącej komunikacji w sprawach związanych ze złożonymi Zamówieniami, w tym ich potwierdzaniem i informowaniem o statusie (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. b RODO),</li>
                            <li>umożliwienia Rejestracji oraz obsługi Konta Klienta założonego w ramach Sklepu Internetowego oraz zapewniania innych funkcjonalności za pośrednictwem Sklepu Internetowego określonych w Regulaminie w ramach zawartej z Klientem umowy o świadczenie usług drogą elektroniczną (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. b RODO),</li>
                            <li>rozpatrywania reklamacji związanych z zawartymi umowami sprzedaży (art. 6 ust. 1 lit. b RODO),</li>
                            <li>rozpatrywania reklamacji dotyczących usług świadczonych drogą elektroniczną (art. 6 ust. 1 lit. b RODO),</li>
                            <li>przyjmowania i obsługi innych niż reklamacje oraz sprawy związane z realizowanymi umowami, zgłoszeń i zapytań kierowanych do Usługodawcy (np. za pośrednictwem danych kontaktowych wskazanych na stronie internetowej Sklepu), co stanowi prawnie uzasadniony interes Usługodawcy (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. f RODO,</li>
                            <li>przyjmowania oświadczeń odstąpienia od zawartych umów sprzedaży na odległość, zgodnie z postanowieniami niniejszego Regulaminu oraz przepisami ustawy z dnia 30 maja 2014 r. o prawach konsumenta, co stanowi prawnie uzasadniony interes Usługodawcy (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. f RODO),</li>
                            <li>rozpatrywania i dochodzenia roszczeń, obrony przed roszczeniami, a także w celach realizacji pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń, co stanowi prawnie uzasadniony interes Usługodawcy (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. f RODO),</li>
                            <li>umożliwienia korzystania z płatności elektronicznych, co stanowi prawnie uzasadniony interes Usługodawcy (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. f RODO),</li>
                            <li>monitorowania sposobu korzystania z usług świadczonych w ramach Sklepu przez Klienta, pod kątem przestrzegania postanowień Regulaminu, a także rozwijania funkcjonalności Sklepu, poprawy działania świadczonych usług za jego pośrednictwem, co stanowi prawnie uzasadniony interes Usługodawcy (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. f RODO),</li>
                            <li>realizacji wymagań prawnych w zakresie przepisów podatkowych i rachunkowych, w szczególności określonych przepisami ustawy z dnia 11 marca 2004 r. o podatku od towarów i usług (VAT), ustawy z dnia z dnia 15 lutego 1992 r. o podatku dochodowym od osób prawnych oraz ustawy z dnia 29 września 1994 r. o rachunkowości (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. c RODO),</li>
                            <li>przechowywania danych dla celów archiwizacyjnych oraz na potrzeby wykazywania prawidłowości spełnienia obowiązków prawnych spoczywających na Usługodawcy, co stanowi jego prawnie uzasadniony interes (podstawa prawna przetwarzania danych: art. 6 ust. 1 lit. f RODO),</li>
                            <li>wysyłania informacji handlowych drogą elektroniczną w postaci Newslettera – jeśli konkretna osoba wyraziła odrębną zgodę na otrzymywanie informacji handlowych drogą elektroniczną,</li>
                            <li>zapisywania danych w postaci plików cookies, gromadzenia danych ze strony internetowej Sklepu oraz wersji mobilnej Sklepu – jeśli konkretna osoba wyraziła na to odrębną zgodę na zasadach określonych w Polityce Plików Cookies obowiązującej na stronie internetowej Sklepu.</li>
                          </ol>

                        </li>
                        <li>Dane osobowe Klientów mogą być udostępniane następującym kategoriom odbiorców:
                          <ol type="a">
                            <li>podwykonawcom zapewniającym wsparcie techniczne Usługodawcy w prowadzeniu i utrzymaniu, a także rozwoju Sklepu takim jak: podmioty świadczące usługi hostingu, dostawcy oprogramowania do zarządzania Sklepem, podmioty świadczące obsługę techniczną oprogramowania Sklepu, dostawcy zapewniający oprogramowanie do wysyłania korespondencji handlowej drogą elektroniczną, agencje marketingowe, dostawcy narzędzi diagnostycznych (np. związanych z ruchem na stronie internetowej Sklepu), z którymi Usługodawca zawarł wymagane prawnie umowy powierzenia przetwarzania danych osobowych;</li>
                            <li>podmiotom wspierającym realizację przez Usługodawcę obowiązujących przepisów prawa oraz uprawnień i obowiązków wynikających z niniejszego Regulaminu, w związku ze świadczeniem usług za pośrednictwem Sklepu, takim jak podmioty świadczące usługi doradcze, z którymi Usługodzwca zawarł wymagane prawnie umowy powierzenia przetwarzania danych osobowych;</li>
                            <li>podmiotom, którym przekazanie danych jest niezbędne w celu zapewnienia prawidłowej realizacji usług za pośrednictwem Sklepu, zgodnie z żądaniem konkretnego Klienta - podmiotom świadczącym usługi płatności elektronicznych (w przypadku wyboru takiej opcji płatności), podmiotom zapewniającym dostawę towaru pod wskazany adres (np. usługi kurierskie), którym dane są udostępniane jako odrębnym administratorom lub z którymi Usługodawca zawarł wymagane prawnie umowy powierzenia przetwarzania danych osobowych (w zależności od statusu tych podmiotów pełnionego wobec przekazanych danych osobowych).</li>
                          </ol>
                        </li>


                      <li>Dane osobowe Klientów, przetwarzane są na terenie Europejskiego Obszaru Gospodarczego (EOG). Usługodawca w uzasadnionych przypadkach (związanych z potrzebą zapewnienia funkcjonalności Sklepu i zarządzania jego działaniem) może przekazywać dane osobowe poza teren EOG w ramach korzystania z usług podwykonawców (spośród kategorii odbiorców wskazanych w punkcie 4 wyżej). W takim wypadku Usługodawca gwarantuje wymagane prawnie środki ochrony danych osobowych, którymi będą (w zależności od przypadku):
                        <ol type="a">
                          <li>przekazanie danych do podwykonawcy zlokalizowanego w państwie trzecim, w stosunku do którego została wydana decyzja stwierdzająca odpowiedni stopień ochrony, zgodnie z wymaganiami art. 45 RODO, ii) przekazanie danych realizowane na podstawie zawartej z podwykonawcą umowy o transfer danych opartej na Standardowych Klauzulach Umownych przyjętych w drodze decyzji przez Komisję Europejską, iii) przekazanie danych realizowane w ramach stosowanych przez podwykonawcę wiążących reguł korporacyjnych, o których mowa w art. 47 RODO, iiii) przekazanie danych odbywające się do podwykonawcy, który uczestniczy w programie Privacy Shield. Więcej informacji dotyczących stosowanych przez Usługodawcę środków bezpieczeństwa związanych z przekazywaniem danych poza obszar EOG można uzyskać kontaktując się z Usługodawcą pod wskazany powyżej adres.</li>
                        </ol>
                      </li>


                      <li>Pozyskane dane osobowe będą przechowywane przez Usługodawcę przez okres realizacji zawartych Umów sprzedaży i do czasu ich prawidłowego rozliczenia, a także przez okres świadczenia usług Sklepu Internetowego przez czas obowiązywania umów o świadczenie usług drogą elektroniczną, a ponadto:
                        <ol type="a">
                          <li>do czasu przedawnienia potencjalnych roszczeń wynikających z umów wskazanych powyżej,</li>
                          <li>przez czas niezbędny do dochodzenia konkretnych roszczeń, z którymi wystąpił Usługodawca lub ich odparcia (jeżeli z roszczeniami wystąpił Klient) w związku z zawartymi umowami, o których mowa wyżej,</li>
                          <li>przez czas wykonywania obowiązków wynikających z przepisów prawa, w tym w szczególności w zakresie regulacji podatkowych i rachunkowych, np. obowiązków związanych z przechowywaniem dokumentacji zgodnie z wymaganiami art. 74 ustawy z dnia 29 września 1994 r. o rachunkowości,</li>
                          <li>przez okres niezbędny do udokumentowania przez Usługodawcę przed organami administracji publicznej, w tym organem nadzoru w zakresie ochrony danych osobowych, prawidłowości spełnienia obowiązków prawnych na nim spoczywających,</li>
                          <li>w celach archiwizacyjnych, gdy dotyczy to historii prowadzonej korespondencji i udzielanych odpowiedzi na zgłaszane zapytania (niezwiązane bezpośrednio z zawartymi umowami) – przez okres nie dłuższy niż 3 lata,</li>
                          <li>do czasu wycofania zgody na przetwarzanie danych lub ich dezaktualizacji (stwierdzonej przez Usługodawcę) – jeżeli przetwarzanie danych odbywa się za zgodą udzieloną przez konkretną osobę.</li>
                        </ol>
                      </li>

                        <li>Usługodawca zapewnia każdemu Klientowi, prawo skorzystania ze wszystkich przysługujących im uprawnień przewidzianych na gruncie RODO, tj. prawa żądania dostępu do swoich danych osobowych, prawa ich sprostowania, usunięcia lub żądania ograniczenia przetwarzania, prawa do przenoszenia danych, a także prawa wniesienia sprzeciwu na ich przetwarzanie, na zasadach i w przypadkach przewidzianych w przepisach RODO.</li>
                        <li>W przypadku przetwarzania danych osobowych przez Usługodawcę w celu realizacji prawnie uzasadnionych interesów (o których była mowa powyżej – art. 6 ust. 1 lit. f RODO), każdemu Klientowi przysługuje prawo do wniesienia sprzeciwu na przetwarzanie danych z przyczyn związanych z jego szczególną sytuacją.</li>
                        <li>W przypadku przetwarzania przez Usługodawcę danych osobowych za zgodą wyrażoną przez Klienta, każdej osobie przysługuje prawo wycofania w dowolnym momencie zgody na przetwarzanie swoich danych bez wpływu na zgodność z prawem przetwarzania tych danych, które miało miejsce przed cofnięciem zgody.</li>
                        <li>Podanie danych osobowych przez Klienta jest dobrowolne.</li>
                        <li> Usługodawca nie będzie prowadził operacji przetwarzania danych Klientów w sposób zautomatyzowany, które jednocześnie będą prowadziły do podejmowania wobec nich decyzji wywołujących skutki prawne lub w podobny sposób istotnie wpływających na ich sytuację.</li>
                        <li> Każdej osobie, której dane osobowe są przetwarzane przez Usługodawcę przysługuje prawo wniesienia skargi w związku z przetwarzaniem jej danych osobowych do organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych Osobowych, mający siedzibę przy ul. Stawki 2, 00-193 Warszawa.</li>
                      </ol><br />

                      <li className='main-list-item'>ODSTĄPIENIE OD UMOWY I ZWROT PRODUKTÓW</li>
                      <ol>

                      <li>Prawo odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa lub na odległość nie przysługuje Konsumentowi w odniesieniu do umów:
                        <ul>
                          <li> o świadczenie usług, jeżeli przedsiębiorca wykonał w pełni usługę za wyraźną zgodą konsumenta, który został poinformowany przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez przedsiębiorcę utraci prawo odstąpienia od umowy;</li>
                          <li> w której przedmiotem świadczenia jest rzecz nieprefabrykowana, wyprodukowana według specyfikacji konsumenta lub służąca zaspokojeniu jego zindywidualizowanych potrzeb;</li>
                          <li> w której przedmiotem świadczenia jest rzecz ulegająca szybkiemu zepsuciu lub mająca krótki termin przydatności do użycia;</li>
                          <li> w której przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym opakowaniu, której po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu;</li>
                          <li> w której przedmiotem świadczenia są rzeczy, które po dostarczeniu, ze względu na swój charakter, zostają nierozłącznie połączone z innymi rzeczami;</li>
                        </ul>
                      </li>
                        <li>Z uwagi na to, że Produkt nie znajduje się w ofercie Usługodawcy i zostanie wyprodukowany na indywidualne Zamówienie Klienta, Klientowi przysługuje prawo do odstąpienia od Umowy sprzedaży wyłącznie do momentu wyprodukowania Produktu.</li>
                        <li>Klient zostanie poinformowany o wyprodukowaniu Produktu poprzez zmianę statusu Zamówienia na Koncie Klienta na „Wyprodukowane” .</li>
                        <li>Klient może odstąpić od zawartej Umowy sprzedaży w terminie wskazanym w pkt. 2 powyżej , poprzez kliknięcie aktywnego przycisku na Koncie Klienta - „Odstąp od umowy”. Funkcja ta przestaje być aktywna w momencie zmiany statusu Zamówienia na „Wyprodukowane”.</li>
                        <li>Skuteczne odstąpienie od umowy uruchamia procedurę zwrotu pieniędzy.</li>
                        <li>Pieniądze zostaną Klientowi zwrócone w terminie  …………….. dni na konto bankowe z którego Klient dokonał płatności za Zamówienie.</li>
                      </ol><br />

                      <li className='main-list-item'>POSTANOWIENIA KOŃCOWE</li>
                      <ol>
                      <li>Umowa o świadczenie Usługi Elektronicznej polegającej na umożliwieniu złożenia Zamówienia w Sklepie Internetowym poprzez wypełnienie Ankiety oraz Formularza Zamówienia zawierana jest na czas oznaczony i ulega rozwiązaniu z chwilą złożenia Zamówienia.</li>
                      <li>Umowa o świadczenie Usługi Elektronicznej polegającej na prowadzeniu Konta Klienta w Sklepie Internetowym zawierana jest na czas nieoznaczony.</li>
                      <li>Umowa o świadczenie Usługi Elektronicznej dostępu do Regulaminu, Formularza Zamówienia, oferty Sklepu internetowego i Polityki prywatności zostaje zawarta z chwilą, gdy Klient wyświetli stronę internetową Sklepu i zawierana jest na czas przeglądania strony tj. do czasu jej zamknięcia.</li>
                      <li>Usługodawca ponosi na zasadach ogólnych odpowiedzialność za należytą staranność oferowanych i udzielanych usług.</li>
                      <li>Klient ponosi odpowiedzialność za to, że podane przez niego dane osobowe są zgodne ze stanem faktycznym.</li>
                      <li>Niedopuszczalne jest podejmowanie przez Klienta działań mogących wpłynąć na poprawne funkcjonowanie Sklepu, korzystanie ze Sklepu Internetowego w sposób sprzeczny z Regulaminem oraz sprzecznych z powszechnie obowiązującymi przepisami prawa, a w szczególności dostarczanie za pośrednictwem Sklepu Internetowego treści o charakterze bezprawnym.</li>
                      <li>Usługodawca zastrzega sobie prawo do występowania przerw technicznych koniecznych dla zapewnienia bezpieczeństwa, konserwacji lub rozbudowy Sklepu. Informacja o przerwach technicznych będzie przekazywana za pośrednictwem Sklepu z wyprzedzeniem co najmniej jednodniowym.</li>
                      <li>Usługodawca może dokonywać zmiany Regulaminu w celu zapewnienia bezpiecznego funkcjonowania Sklepu, w szczególności zapobiegania nadużyciom, a także w celu uaktualnienia usług oferowanych Klientom. Informacja o zmianie Regulaminu zostanie opublikowana na stronie internetowej Sklepu i wysłana na adresy email przypisane do Kont Klienta zarejestrowanych w Sklepie. Zmiany Regulaminu nie naruszają praw nabytych Klientów i nie będą miały zastosowania do już zawartych Umów, chyba że Klient wyrazi na to zgodę</li>
                      <li>Każdy użytkownik może skontaktować się z Usługodawcą korzystając z adresu e-mail: sklep@pure4me.pl</li>
                      <li> Regulamin wchodzi w życie z chwilą jego opublikowania na stronie internetowej Sklepu.</li>
                      </ol><br />

                      {/*  end of main list */}
                    </ol>
                  </p>
                </Grid.Column>
              </Grid>
            </Container>
          </Segment>
        </Segment>
      </MainTopBg>
    );
  }
}